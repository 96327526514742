import React, { useMemo } from "react";
import { graphql } from "gatsby";
import { useTranslation } from "@w3f/gatsby-theme-w3f/src/utils/i18n";
import Layout from "@w3f/gatsby-theme-w3f/src/components/site/Layout";
import SEO from "@w3f/gatsby-theme-w3f/src/components/site/SEO";
import Container from "layouts/Container";
import Hero from "components/Hero";
import ArrowLink from "elements/ArrowLink";
import ResearchItemGrid from "../components/ResearchItemGrid";
import TeamGrid from "../../../members/src/TeamGrid";
import Cta from "components/Cta";
import { sortCollectionItems } from "utils/sort-collection-items";

const Research = ({ data: { allResearchItems, allResearchTeamMembers, page, footerImage } }) => {
  const { t } = useTranslation();
  const { researchItems, researchTeam, seo } = page.frontmatter;
  const meta = seo ? { ...seo } : {}; // In case the seo object is null

  const sortedResearchItems = useMemo(
    () => sortCollectionItems(allResearchItems.nodes, researchItems),
    [allResearchItems, researchItems]
  );

  const sortedResearchTeam = useMemo(
    () => sortCollectionItems(allResearchTeamMembers.nodes, researchTeam),
    [allResearchTeamMembers, researchTeam]
  );

  return (
    <Layout footerImage={footerImage}>
      <SEO {...meta} />

      <Container className="container--t-margin-sm container--b-margin-sm" noOverflow={false}>
        <Hero
          title={t("Research")}
          content={
            <p>
              Our industry-leading research team confronts the most difficult research problems standing between us and
              Web3.
            </p>
          }
          ctaLink={{ url: "https://research.web3.foundation", title: "Visit the Research Website" }}
          delayedAside={false}
          asideTitle="Web3 Foundation Research is conducted in-house and in collaboration with various projects and research groups. The team is primarily located in Zug, Switzerland."
          asideContent={
            <>
              <p>Our research focuses on the areas of:</p>
              <ul>
                <li>
                  Decentralized algorithms:
                  <br />
                  consensus, optimization, game theory
                </li>
                <li>Cryptography</li>
                <li>Networking</li>
              </ul>
              <ArrowLink stretched className="t-md" to="https://riot.im/app/#/room/#web3foundation:matrix.org">
                Talk to us on Riot at #w3f:matrix.org
              </ArrowLink>
            </>
          }
        />
      </Container>

      <Container withBackground>
        <ResearchItemGrid items={sortedResearchItems} />
      </Container>
      <Container className="container--v-margin">
        <TeamGrid title="Meet the Research Team" members={sortedResearchTeam} />
      </Container>
      <Container className="container--v-margin-lg">
        <Cta
          title="Interested in joining the Team?"
          link={{
            to: "https://web3.bamboohr.com/jobs/",
            title: "Check out our available positions",
          }}
        />
      </Container>
    </Layout>
  );
};

export default Research;

export const query = graphql`
  query ($language: String!) {
    ...Locales
    page: markdownRemark(fileAbsolutePath: { regex: "//pages/research.md/" }) {
      frontmatter {
        researchItems: research_items
        researchTeam: research_team
        seo {
          ...Seo
        }
      }
    }
    allResearchItems: allMarkdownRemark(
      filter: { fields: { langKey: { eq: $language } }, fileAbsolutePath: { regex: "//(research-items)//" } }
    ) {
      nodes {
        html
        frontmatter {
          id
          title
          image {
            childImageSharp {
              gatsbyImageData(layout: FULL_WIDTH, formats: [AUTO, WEBP, AVIF])
            }
          }
          link {
            title
            url
          }
        }
        fields {
          langKey
          slug
        }
      }
    }
    allResearchTeamMembers: allMarkdownRemark(
      filter: { fields: { langKey: { eq: $language } }, fileAbsolutePath: { regex: "//members//" } }
    ) {
      nodes {
        html
        frontmatter {
          id
          image {
            childImageSharp {
              gatsbyImageData(layout: FULL_WIDTH, formats: [AUTO, WEBP, AVIF], placeholder: NONE)
            }
          }
          name
          job_title
          description
        }
        fields {
          langKey
          slug
        }
      }
    }
    footerImage: file(name: { eq: "research-large" }) {
      ...BgImage
    }
  }
`;
