import React from "react";
import { InView, staggerStyle } from "utils/animation";
import TeamGridMember from "./TeamGridMember";

import "./TeamGrid.scss";

const TeamGrid = ({ title, members }) => {
  return (
    <InView className="team-grid">
      <div className="team-grid__title">
        <h2 className="t-title-2 a-fade-up">{title}</h2>
      </div>
      <div className="team-grid__members">
        {members.map((member, index) => (
          <TeamGridMember
            key={index}
            className="team-grid__member-item a-fade-up"
            style={staggerStyle(2 * index + 1)}
            body={member.html}
            {...member.frontmatter}
          />
        ))}
      </div>
    </InView>
  );
};

export default TeamGrid;
