import React from "react";
import Container from "layouts/Container";
import MediaCard from "components/MediaCard";
import { InView, staggerStyle } from "utils/animation";

import "./ResearchItemGrid.scss";

const ResearchItemGrid = ({ items }) => {
  return (
    <InView>
      <h2 className="t-title-2 a-fade-up">Papers & Blogs</h2>

      <Container className="container--small-no-padding container--t-margin-sm" noOverflow={false}>
        <div className="research-item-grid">
          {items.map((item, index) => (
            <MediaCard
              key={index}
              className="research-item-grid__card a-fade-up"
              {...item.frontmatter}
              style={staggerStyle(2 + 2 * index)}
              vertical
            />
          ))}
        </div>
      </Container>
    </InView>
  );
};

export default ResearchItemGrid;
