import React from "react";
import { GatsbyImage, getImage } from "gatsby-plugin-image";
import clsx from "clsx";

import "./TeamGridMember.scss";

const TeamGridMember = ({ className, image, name, job_title, body, ...props }) => {
  const imageData = getImage(image);
  return (
    <div className={clsx("team-grid-member", className)} {...props}>
      <div className="team-grid-member__image">
        <GatsbyImage image={imageData} alt="" />
      </div>
      <h3 className="team-grid-member__name">{name}</h3>
      <p className="team-grid-member__job-title t-light-gray">{job_title}&nbsp;</p>
      <div className="team-grid-member__description t-line-break t-sm" dangerouslySetInnerHTML={{ __html: body }} />
    </div>
  );
};

export default TeamGridMember;
